
































import Staking from '@/components/Staking.vue'
import { Component, Vue } from 'vue-property-decorator'
import { Account } from '@/models/Account'
import { Conf } from '@/models/Conf'
import { getAPIConfiguration, getAccounts, getPriceInUSDT } from '@/clients/cpinblocks'
import BigNumber from 'bignumber.js'

@Component({
	components: {
		Staking,
	},
})
export default class StakingView extends Vue {
	accounts: Account[] = []
	conf: Conf | null = null
	loading = true
	// hardcoded, should be retrieved by the API
	maintenance = false
	prices: Map<string, BigNumber | null> = new Map<string, BigNumber>()

	async mounted (): Promise<void> {
		this.conf = await getAPIConfiguration()
		this.accounts = await getAccounts(this.$store.state.jwt, false)
		if (this.conf && !this.conf.staking) {
			this.conf.staking = []
		}
		if (this.conf && this.conf.staking) {
			for (const s of this.conf.staking) {
				if (!this.prices.has(s.currency)) {
					this.prices.set(s.currency, await getPriceInUSDT(s.currency))
				}
			}
		}
		this.loading = false
	}

	async onUpdate (): Promise<void> {
		this.accounts = await getAccounts(this.$store.state.jwt, false)
	}
}
