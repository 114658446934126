





























































































































































































































import ClipboardCopy from '@/components/ClipboardCopy.vue'
import { StakingConf } from '@/models/Conf'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { getStaking, claimRewards } from '@/clients/cpinblocks'
import { Account } from '@/models/Account'
import { Staking } from '@/models/Staking'
import BigNumber from 'bignumber.js'
import AddStake from '@/components/AddStake.vue'
import AddUnstake from '@/components/AddUnstake.vue'

@Component({
	components: { AddStake, AddUnstake, ClipboardCopy },
})
export default class StakingComponent extends Vue {
	@Prop() stakingConf!: StakingConf
	@Prop() accounts!: Account[]
	@Prop() currencyPriceUSDT!: BigNumber

	// accounts: Account[] | null = null
	account: Account | null = null
	claimable: BigNumber | null = null
	claimLoading = false
	hardTimer?: ReturnType<typeof setTimeout>
	loading = true
	refreshFrequencyInMs = 100
	showStakeDialog = false
	showUnstakeDialog = false
	softTimer?: ReturnType<typeof setTimeout>
	stakeLoading = false
	staking: Staking | null = null
	delayFromStartSec = 0
	delayFromEndSec = 0
	unstakeLoading = false

	get balance (): string {
		BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_DOWN })
		return this.account && this.account.balance ? this.account?.balance.toFixed(0) : '0'
	}

	get endDay (): string {
		return Math.floor(Math.abs(this.delayFromEndSec / 86400)).toString()
	}

	get endHour (): string {
		return Math.floor(Math.abs((this.delayFromEndSec / 3600) % 24)).toLocaleString(this.$store.state.locale, { minimumIntegerDigits: 2 })
	}

	get endMin (): string {
		return Math.floor(Math.abs((this.delayFromEndSec / 60) % 60)).toLocaleString(this.$store.state.locale, { minimumIntegerDigits: 2 })
	}

	get endSec (): string {
		return Math.floor(Math.abs(this.delayFromEndSec % 60)).toLocaleString(this.$store.state.locale, { minimumIntegerDigits: 2 })
	}

	get startDay (): string {
		return Math.floor(Math.abs(this.delayFromStartSec / 86400)).toString()
	}

	get startHour (): string {
		return Math.floor(Math.abs(this.delayFromStartSec / 3600) % 24).toLocaleString(this.$store.state.locale, { minimumIntegerDigits: 2 })
	}

	get startMin (): string {
		return Math.floor(Math.abs((this.delayFromStartSec / 60) % 60)).toLocaleString(this.$store.state.locale, { minimumIntegerDigits: 2 })
	}

	get startSec (): string {
		return Math.floor(Math.abs(this.delayFromStartSec % 60)).toLocaleString(this.$store.state.locale, { minimumIntegerDigits: 2 })
	}

	get showStakeCards () : boolean {
		return this.delayFromEndSec > 0
	}

	get showUnstakeCards () : boolean {
		return true
	}

	get showClaimCard () : boolean {
		return this.delayFromStartSec < 0
	}

	get enabledStake (): boolean {
		if (this.$store.state.owner.locked === 'SOFT' || this.$store.state.owner.locked === 'HARD') {
			return false
		}
		if (this.account && this.account.balance && this.stakingConf && this.stakingConf.stakeMin) {
			return this.account.balance.gte(this.stakingConf.stakeMin)
		}
		return false
	}

	get enabledUnstake (): boolean {
		if (this.$store.state.owner.locked === 'HARD') {
			return false
		}
		return this.staking !== null && this.staking.staked.gt(0)
	}

	async mounted (): Promise<void> {
		await this.hardRefresh()
		this.hardTimer = setInterval(this.hardRefresh, 60000)
		await this.softRefresh()
		this.softTimer = setInterval(this.softRefresh, 100)
		for (const acc of this.accounts) {
			if (acc.type.toLowerCase() === this.stakingConf.type.toLowerCase() && acc.currency.toLowerCase() === this.stakingConf.currency.toLowerCase()) {
				this.account = acc
			}
		}
		this.loading = false
	}

	beforeDestroy () {
		this.hardTimer && clearInterval(this.hardTimer)
		this.softTimer && clearInterval(this.softTimer)
	}

	async hardRefresh (): Promise<void> {
		this.staking = await getStaking(this.$store.state.jwt, this.stakingConf.id)
		this.claimable = this.staking.rewards
		for (const acc of this.accounts) {
			if (acc.type.toLowerCase() === this.stakingConf.type.toLowerCase() && acc.currency.toLowerCase() === this.stakingConf.currency.toLowerCase()) {
				this.account = acc
			}
		}
	}

	async softRefresh (): Promise<void> {
		this.delayFromEndSec = (this.stakingConf.endExclusive.getTime() - new Date().getTime()) / 1000
		this.delayFromStartSec = (this.stakingConf.startInclusive.getTime() - new Date().getTime()) / 1000
		if (this.claimable && this.staking && this.staking.rewardsPerSec.gt(0) && this.delayFromEndSec > 0) {
			this.claimable = this.claimable.plus(this.staking.rewardsPerSec.multipliedBy(this.refreshFrequencyInMs / 1000))
		}
		for (const acc of this.accounts) {
			if (acc.type.toLowerCase() === this.stakingConf.type.toLowerCase() && acc.currency.toLowerCase() === this.stakingConf.currency.toLowerCase()) {
				this.account = acc
			}
		}
	}

	openStakeDialog (): void {
		this.stakeLoading = true
		this.showStakeDialog = true
	}

	openUnstakeDialog (): void {
		this.unstakeLoading = true
		this.showUnstakeDialog = true
	}

	async onStake (): Promise<void> {
		this.$emit('onStake', {})
		await this.hardRefresh()
		this.showStakeDialog = false
		this.stakeLoading = false
	}

	async onUnstake (): Promise<void> {
		this.$emit('onUnstake', {})
		await this.hardRefresh()
		this.showUnstakeDialog = false
		this.unstakeLoading = false
	}

	async claim (): Promise<void> {
		this.claimLoading = true
		await claimRewards(this.$store.state.jwt, this.stakingConf.id)
		this.$emit('onClaim')
		await this.hardRefresh()
		this.claimLoading = false
	}
}
