














































import { getAPIConfiguration, unstake } from '@/clients/cpinblocks'
import { Conf, StakingConf } from '@/models/Conf'
import Code2FADialog from '@/components/Code2FADialog.vue'
import CurrencyInput from '@/components/CurrencyInput.vue'
import { alertRawError } from '@/utils'
import { BigNumber } from 'bignumber.js'
import { Component, Prop, Vue } from 'vue-property-decorator'
import type { Currency } from '@/types'
import { Staking } from '@/models/Staking'

@Component({
	components: { CurrencyInput, Code2FADialog },
})
export default class AddUnstake extends Vue {
	@Prop() balance!: BigNumber
	@Prop() currency!: Currency
	@Prop() staking!: Staking
	@Prop() stakingConf!: StakingConf

	show2FADialog = false
	private conf: Conf | null = null
	creating = false
	loading = true

	async created (): Promise<void> {
		this.conf = await getAPIConfiguration()
		this.loading = false
	}

	async cancel (): Promise<void> {
		this.$emit('cancel')
	}

	async open2FADialog (): Promise<void> {
		this.creating = true
		this.show2FADialog = true
	}

	async createUnstakeOperation (object: any): Promise<void> {
		const	code2FA = object.code
		this.show2FADialog = false
		this.creating = true
		try {
			await unstake(this.$store.state.jwt, this.stakingConf.id, code2FA)
			this.$emit('success')
		} catch (error) {
			alertRawError(error.message)
			this.creating = false
			this.$emit('fail')
			return
		}
		this.creating = false
	}
}
